<template>
  <v-container fluid>
    <head-title
      text="Recibos de Caja"
      :to="{ name: 'accounting-register-buy-home' }"
    ></head-title>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12" md="3">
        <business-name-field v-model="branchs" :returnId="false"></business-name-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
          label="Sucursal"
          :items="branchs"
          v-model="company"
          item-text="second_name"
          item-value="id"
        ></v-select>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
          :items="[
            { text: 'Por mes', value: 0 },
            { text: 'Por Fecha', value: 1 },
          ]"
          label="Mostrar Datos"
          v-model="searchType"
        ></v-select>
      </v-col>
      <v-col cols="12" md="2" v-if="searchType === 1">
        <date-field label="Fecha" v-model="date"></date-field>
      </v-col>
      <v-col cols="12" md="2" v-else>
        <date-field label="Fecha" v-model="date" type="month"></date-field>
      </v-col>
      <v-col cols="12" md="1">
        <v-select
          label="Estado"
          :items="status"
          item-value="value"
          item-text="name"
          persistent-hint
          v-model="filterByStatus"
          clearable
        ></v-select>
      </v-col>
      <v-col cols="12" md="1" class="text-right">
        <v-btn class="mx-2" fab dark small color="indigo" @click="getReceipts()">
          <v-icon>mdi-database-search</v-icon></v-btn
        >
      </v-col>
      <v-col cols="12" md="1" class="text-left">
        <v-btn fab dark small color="blue darken-3" @click="exportXlsxReport()">
          <v-icon color="white">mdi-file</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="data"
      hide-default-footer
      disable-sort
      disable-pagination
      class="elevation-1"
    >
      <template v-slot:[`item.code`]="{ item }">
        <v-btn text color="primary" @click="getDetail(item.id)">
          {{ item.code }}
        </v-btn>
      </template>
      <template v-if="data.length > 0" v-slot:[`item.is_editable`]="{ item }">
        <span v-if="item.is_editable">Pendiente</span>
        <span v-if="!item.is_editable">Finalizado</span>
      </template>
      <template v-if="data.length > 0" v-slot:[`item.amount`]="{ item }">
        Q {{ item.amount | currency }}
      </template>
      <template v-if="data.length > 0" v-slot:[`item.date`]="{ item }">
        {{ item.date | date }}
      </template>
      <template v-slot:[`item.payment_cash_receipt`]="{ item }">
        <div class="text-center" v-if="item.is_editable == false">
          <v-menu offset-y open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed v-bind="attrs" v-on="on">
                Depósitos <v-icon dark> mdi-chevron-down </v-icon>
              </v-btn>
            </template>
            <div v-for="(i, index) in item.payment_cash_receipt" :key="index">
              <v-list v-if="i.payment_type == 'PAYD'">
                <v-list-item>
                  <v-list-item-title>{{ i.document }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </div>
          </v-menu>
        </div>
        <div v-else>-</div>
      </template>
    </v-data-table>
    <detail-receipt
      v-if="receipt != undefined"
      :dialog="detailDialog"
      :receipt="receipt"
      @close="(detailDialog = false), (receipt = undefined)"
    ></detail-receipt>
  </v-container>
</template>
<script>
import requests from "@/services/requests";
import xlsx from "@/services/sheetJs";
export default {
  name: "CashReceiptList",
  components: {
    HeadTitle: () => import("@/components/base/HeaderTitle"),
    BusinessNameField: () => import("@/components/fields/BusinessNameField"),
    DateField: () => import("@/components/fields/DateField"),
    DetailReceipt: () => import("@/components/accounting/DetailReceipt"),
  },
  data() {
    return {
      columns: [
        [
          "No. Recibo Caja",
          "Fecha",
          "Cliente",
          "NIT",
          "Autorizacion",
          "Serie",
          "Número",
          "Cantidad",
          "Descripción",
          "Sucursal",
          "Estado",
        ],
      ],
      loading: false,
      searchType: 0,
      headers: [
        { text: "No.", value: "code" },
        { text: "Sucursal", value: "company.name" },
        { text: "Cliente", value: "customer" },
        { text: "", value: "payment_cash_receipt" },
        { text: "Fecha", value: "date" },
        { text: "Total", value: "amount" },
        { text: "Creado por", value: "created_by.username" },
        { text: "Estado", value: "is_editable" },
      ],
      branchs: [],
      data: [],
      company: 0,
      receipt: undefined,
      detailDialog: false,
      filterByStatus: undefined,
      date: undefined,
      status: [
        { name: "TODOS", value: undefined },
        { name: "PENDIENTES", value: true },
        { name: "FINALIZADOS", value: false },
      ],
    };
  },
  mounted() {
    this.searchType = 1;
    this.date = new Date().toISOString().substring(0, 10);
  },
  methods: {
    getReceipts() {
      let uri = "api/cash-receipt/";
      if (this.branchs.length == 0 || this.branchs == undefined) {
        this.$toasted.global.info({
          message: "Debe seleccionar una Razón Social",
        });
        return;
      }
      if (!this.company > 0 || this.company == undefined) {
        this.$toasted.global.info({
          message: "Debe seleccionar una Sucursal",
        });
        return;
      }
      if (this.date == undefined) {
        this.$toasted.global.info({
          message: "Debe seleccionar una fecha",
        });
        return;
      }
      this.loading = true;
      uri +=
        "?business=" + this.branchs[0].business_name_id + "&sucursal=" + this.company;

      if (this.searchType === 0) {
        uri +=
          "&month=" + this.date.substring(5, 7) + "&year=" + this.date.substring(0, 4);
      } else if (this.searchType === 1) {
        uri += "&date=" + this.date;
      }
      if (this.filterByStatus != undefined) {
        switch (this.filterByStatus) {
          case true:
            uri += "&status=True";
            break;
          case false:
            uri += "&status=False";
            break;
        }
      }

      requests.get(uri).then((res) => {
        this.data = res.data.results;
        this.loading = false;
      });
    },
    getDetail(id) {
      this.receipt = id;
      this.detailDialog = true;
    },

    async exportXlsxReport() {
      if (this.data.length > 0 && this.data != undefined) {
        var datos = this.data
          .map((row) =>
            row.detail_cash_receipt.map((dt) => ({
              code: row.code,
              date: row.date,
              customer: row.customer,
              nit: row.nit,
              authorization: dt.authorization,
              serie: dt.serie,
              number: dt.number,
              amount: dt.amount,
              description: dt.description,
              company: row.company.second_name,
              is_cancel: row.is_annulment ? "ANULADO" : "ACTIVO",
            }))
          )
          .flat();

        await xlsx.exportXlsx(
          datos,
          this.columns,
          "Recibos de Caja",
          this.company + "-" + this.date + ".xlsx"
        );
      } else {
        this.$toasted.global.info({
          message: "No hay datos",
        });
      }
    },
  },
};
</script>
