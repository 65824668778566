var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('head-title',{attrs:{"text":"Recibos de Caja","to":{ name: 'accounting-register-buy-home' }}}),_c('v-divider'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('business-name-field',{attrs:{"returnId":false},model:{value:(_vm.branchs),callback:function ($$v) {_vm.branchs=$$v},expression:"branchs"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"label":"Sucursal","items":_vm.branchs,"item-text":"second_name","item-value":"id"},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"items":[
          { text: 'Por mes', value: 0 },
          { text: 'Por Fecha', value: 1 } ],"label":"Mostrar Datos"},model:{value:(_vm.searchType),callback:function ($$v) {_vm.searchType=$$v},expression:"searchType"}})],1),(_vm.searchType === 1)?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('date-field',{attrs:{"label":"Fecha"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1):_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('date-field',{attrs:{"label":"Fecha","type":"month"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-select',{attrs:{"label":"Estado","items":_vm.status,"item-value":"value","item-text":"name","persistent-hint":"","clearable":""},model:{value:(_vm.filterByStatus),callback:function ($$v) {_vm.filterByStatus=$$v},expression:"filterByStatus"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"1"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"indigo"},on:{"click":function($event){return _vm.getReceipts()}}},[_c('v-icon',[_vm._v("mdi-database-search")])],1)],1),_c('v-col',{staticClass:"text-left",attrs:{"cols":"12","md":"1"}},[_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"blue darken-3"},on:{"click":function($event){return _vm.exportXlsxReport()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-file")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.data,"hide-default-footer":"","disable-sort":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.getDetail(item.id)}}},[_vm._v(" "+_vm._s(item.code)+" ")])]}},(_vm.data.length > 0)?{key:"item.is_editable",fn:function(ref){
        var item = ref.item;
return [(item.is_editable)?_c('span',[_vm._v("Pendiente")]):_vm._e(),(!item.is_editable)?_c('span',[_vm._v("Finalizado")]):_vm._e()]}}:null,(_vm.data.length > 0)?{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" Q "+_vm._s(_vm._f("currency")(item.amount))+" ")]}}:null,(_vm.data.length > 0)?{key:"item.date",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.date))+" ")]}}:null,{key:"item.payment_cash_receipt",fn:function(ref){
        var item = ref.item;
return [(item.is_editable == false)?_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":""}},'v-btn',attrs,false),on),[_vm._v(" Depósitos "),_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,true)},_vm._l((item.payment_cash_receipt),function(i,index){return _c('div',{key:index},[(i.payment_type == 'PAYD')?_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_vm._v(_vm._s(i.document))])],1)],1):_vm._e()],1)}),0)],1):_c('div',[_vm._v("-")])]}}],null,true)}),(_vm.receipt != undefined)?_c('detail-receipt',{attrs:{"dialog":_vm.detailDialog,"receipt":_vm.receipt},on:{"close":function($event){(_vm.detailDialog = false), (_vm.receipt = undefined)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }